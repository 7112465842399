import { Container, Heading, Link } from "@chakra-ui/layout";
import { Text } from "@chakra-ui/layout";
import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Table, Td, Tr } from "@chakra-ui/react";
import { DiscordLink } from "../components/DiscordLink";

const PlusIcon = ( props : any ) => {
    return(
        <Box mt="-3px" pr="3px">
            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="10.5" y1="2.18557e-08" x2="10.5" y2="21" stroke="black"/>
            <line x1="4.37114e-08" y1="10.5" x2="21" y2="10.5" stroke="black"/>
            </svg>

        </Box>
    );
}

const MinusIcon = ( props : any ) => {
    return(
        <Box mt="-3px" pr="3px">
            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="4.37114e-08" y1="10.5" x2="21" y2="10.5" stroke="black"/>
            </svg>
        </Box>
    );
}

const MuniAccordion = ( props : any ) => {
    const heading = props.heading;
    const contents = props.contents;

    return(
        <AccordionItem p={0} borderColor="black">
            {
                ({ isExpanded }) => (
                    <>
                    <AccordionButton p="0.3em 0 0">
                        <Box flex="1" textAlign="left">
                            <Heading as='h2' size="xl" margin="0.1em 0 0.3em" fontStyle="italic">
                                {heading}
                            </Heading>
                        </Box>
                        {
                            isExpanded ? (
                                    <MinusIcon/>
                                ) : (
                                    <PlusIcon/>
                            )
                        }
                    </AccordionButton>
                    <AccordionPanel p="0 0 4em" maxWidth="75ch">
                        {contents}
                    </AccordionPanel>
                    </>
                )
            }
        </AccordionItem>
    );
}

const Roadmap = (props : any) => {
    return(
        <>
        <Container>
            <Accordion defaultIndex={[0, 1]} allowMultiple>
                <MuniAccordion heading="The Project" contents={
                    <>
                        <Text mb={3}>Our collection consists of 5,400 golf balls that are fully generated, stored, and rendered on-chain. Each ball lands with a set of unique attributes like your score, yardage, weather type, ground type, ball color, brand, creatures, and more. </Text>
                        <Text mb={3}>As we expand the club’s facilities, these golf balls and their attributes will allow you to generate and play unique holes, assemble a course, and recieve exclusive access to <em>The Pro Shop™</em>.</Text>
                        <Text>Take a swing and see where you land!</Text>
                    </>
                } />
                <MuniAccordion heading="Links" contents={
                    <>
                        <Link href="https://etherscan.io/address/0x06f650198cfcd186d45d9b2e564db755dbc4a8cd">Etherscan</Link>&nbsp;
                        <Link href="https://twitter.com/munigolfclub">Twitter</Link>&nbsp;
                        <Link href={DiscordLink}>Discord</Link>
                    </>
                } />
                <MuniAccordion heading="The Club" contents={
                    <>
                        <Text pb={2}>
                            Your golf ball is your membership to the club house. As the community at Municipal Golf Club grows, we aim to expand into unique playable holes and course building derived from the metadata attributes of everyone’s golf ball primatives. We can’t wait to get out on the courses with you all.
                        </Text>
                    </>
                } />
                <MuniAccordion heading="Course Map" contents={
                    <>
                        <Text pb={2}>
                            We are approaching the roadmap like a game of golf, we’re taking it one swing at a time. We have an idea of how we want to build out the club, but we also want to give ourselves the flexibility to decide on the best next move. While the roadmap is subject to change, it will always be with the best intentions for our early supporters and community.
                        </Text>
                        <Text>
                            Tee off with a fresh ball and join our <Link href={DiscordLink}>Discord</Link> to keep up to date on the latest announcements.
                        </Text>
                    </>
                } />
                <MuniAccordion heading="Fully On-Chain" contents={
                    <>
                    <Text pb={2}>
                        All of the elements that make up the art of the golf ball is stored forever on the Ethereum blockchain. There is no IPFS used whatsoever and an API will only be used to cache and render the on-chain data quicker as we build out more tools.
                    </Text>
                    <Text>
                        Most elements will be made available as SVGs with a Creative Commons 0 license except the brand logos that we have created. We are reserving the copyright on the brand logos for the moment but if we feel it's in the interest of the community we will reassess. 
                    </Text>
                    </>
                } />
                <MuniAccordion heading="Edition & Price" contents={
                    <>
                    <Text>
                        Editions: 5,400
                    </Text>
                    <Text>
                        Mint Price: 0.05 ETH
                    </Text>
                    <Text>
                        Secondary Royalty: 5%
                    </Text>
                    <Text>
                        Reserved: 180 reserved for giveaways and founding team
                    </Text>
                    </>
                } />
                <MuniAccordion heading="Team" contents={
                    <>
                    <Table variant="team">
                        <Tr><Td pl={0}>invdr.eth</Td><Td>Art, Programming, Design</Td></Tr>
                        <Tr><Td pl={0}>t-vr.eth</Td><Td>Art, Design, Community</Td></Tr>
                        <Tr><Td pl={0}>b-t</Td><Td>Project Management, Writing, Community, Social</Td></Tr>
                    </Table>
                    </>
                } />
            </Accordion>
        </Container>
        </>
    )
}

export default Roadmap;