import { Box, Button, Spinner } from "@chakra-ui/react";

const ViewBallsButton = ( props : any ) => {
    const walletAddress = props.walletAddress;
    const onClick = props.onClick;
    const waitingFor = props.waitingFor;

    if ( walletAddress !== "" ) {
        return(
            <Box>
                <Button onClick={onClick}>View My Collection{ waitingFor ? <Spinner ml="9px" mr="-2px" emptyColor="white" thickness="1px" size="sm" color="#2B7A06"/> : null }</Button>
            </Box>
        );
    }
    return(<></>);
}

export default ViewBallsButton;