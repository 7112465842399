import { Image } from "@chakra-ui/image";
import { Box, Divider, Text, Table, Td, Tr, SimpleGrid} from "@chakra-ui/react";
import { Ball, BallAttribute } from "./Ball";

const BallInformation = ( props : any ) => {

    const ball : Ball = props.ball;
    const attributes = ball.attributes?.map(( attribute : BallAttribute ) => {
        if ( attribute.value !== "None") {
            return(<Tr><Td p="0.4em 0" w="40%"><Text fontFamily="monospace" textTransform="uppercase" fontSize="12px">{attribute.trait_type}</Text></Td><Td p="0.4em 0">{attribute.value}</Td></Tr>);
        }
        return (null);
    });

    return(
        <>
        <Box>
            <Text pt={4}>{ball.name}</Text>
            <Image key={ball.name} src={ball.image}/>
            <Table>
                
            {attributes}
            </Table>
        </Box>
        </>
    );
}


const BallGallery = ( props : any ) => {

    const balls : Ball[] = props.balls;
    const isPressed = props.isPressed;
    const optionListItems = balls.map((ball : Ball) => {
        return(<BallInformation key={ball.name} ball={ball}/>);
    });

    if ( balls.length > 0 && isPressed ) {

        return (
            <>
            <SimpleGrid width="100%" columns={[2, 2, 3, 3, 4, ]} p={2} gap={2} mb="25px">
                {optionListItems}
            </SimpleGrid>
            <Box p={2}>
                <Divider borderColor="black" mb="25px"></Divider>
            </Box>
            </>
        );
    }
    return (null);

}

export default BallGallery;