import { Button } from "@chakra-ui/react";

const MintButton = ( props : any ) => {

    const onClick = props.onClick;
    const isMintingActive = props.isMintingActive;
    const multiplier = props.currentCount;

    if ( isMintingActive ) {
        return (
            <Button onClick={onClick} bg="#003F30" color="white">
                Mint ({(multiplier * 0.05).toFixed(2)}Ξ)
            </Button>
        );
    }
    
    return (
        <Button>
            Connect to Mint
        </Button>
    );
}

export default MintButton;