import { useState, useEffect } from "react";
import { Button } from "@chakra-ui/button";
import { Box, Container, Heading, HStack, VStack, Text, Image } from "@chakra-ui/react";

import ThwackTextImage from '../assets/Thwack.svg';

import { checkMintActive, connectWallet, getCurrentWalletConnected, getMintedBallsByWallet, getNumberOfMintedBalls, mintBall, tryBalls } from "../utils/interact";
import BallGallery from "./BallGallery";
import StatusText from "../components/StatusText";
import ConnectWalletButton from "../components/ConnectWalletButton";
import MintButton from "../components/MintButton";
// import { DiscordLink } from "../components/DiscordLink";
import ViewBallsButton from "../components/ViewBallsButton";
// import { stat } from "fs";

declare global {
    interface Window {
        ethereum:any;
        contract:any;
    }
}


const ThwackText = ( props : any ) => {

    const show = props.show;

    if ( show === true ) {
        return(
            <Image src={ThwackTextImage} display="block" position="fixed" width="100%" zIndex="999" top="50%" marginTop="-30%" left="0"/>
        );
    }

    return (
        <Image src={ThwackTextImage} display="none"/>
    );
}




const Minter = (props : any) => {

    const [walletAddress, setWallet] = useState("");
    const [status, setStatus] = useState("");
    const [transaction, setTransaction] = useState("");
    const [mintActive, setMintStatus] = useState(true);
    const [totalSupply, setTotalSupply] = useState("");
    const [currentMintCount, setCurrentMintCount] = useState("");
    const [showThwack, setShowThwack] = useState(false);
    const [amountToMint, setAmountToMint] = useState(1);
    const [ballsForGallery, setBallsForGallery] = useState([{}]);
    const [waitingForBallsForGallery, setWaitingForBallsForGallery] = useState(false);
    const [pressedForBalls, setPressedForBalls] = useState(false);
    // const [name, setName] = useState("");
    // const [description, setDescription] = useState("");
    // const [url, setURL] = useState("");


    useEffect( () => {
        async function fetchData() {
            const{address, status} = await getCurrentWalletConnected();
            const{success} = await checkMintActive();
            const{issued, totalMax} = await getNumberOfMintedBalls();
            setWallet(address);
            setStatus(status);
            setMintStatus(success);
            console.log(success);
            setTotalSupply(totalMax);
            setCurrentMintCount(issued);
            addWalletListener();
        }
        fetchData();
    }, []);

    const connectWalletPressed = async() => {
        const walletResponse = await connectWallet();
        // setStatus(walletResponse.status);
        setWallet(walletResponse.address);
    };

    const onMintPressed = async() => {
        setShowThwack(true);
        setTimeout(()=>{ setShowThwack(false)}, 3600);
        const { status, tx } = await mintBall(amountToMint);
        setStatus(status);
        setTransaction(tx);
    }

    function incrementCount() {
        if ( amountToMint + 1 <= 9) {
            setAmountToMint(amountToMint + 1);
        }
    }

    function decrementCount() {
        if ( amountToMint - 1 >= 1 ) {
            setAmountToMint(amountToMint - 1);
        }
    }

    function addWalletListener() {
        if ( window.ethereum ){
            window.ethereum.on("accountsChanged", (accounts : any) =>{
                if ( accounts.length > 0 ){
                    setWallet(accounts[0]);
                    // setStatus("MetaMask is now connected");
                } else {
                    setWallet("");
                    // setStatus("Connect MetaMask wallet to start");
                }
            });
        } else {
            // setStatus(
            //     "Connect MetaMask wallet to start"
            // );
        }
    }

    const getBallsMintedByWallet = async() => {
        setWaitingForBallsForGallery(true);
        const result = await getMintedBallsByWallet(walletAddress);
        let balls = await tryBalls(result.ballIds);
        try { 
            if (balls !== undefined ) {
                setBallsForGallery(balls);
                setWaitingForBallsForGallery(false);
                setPressedForBalls(true);
            }
        } catch {

        }
    }

    return (
        <>
        <StatusText status={status} displayText="View transaction on Etherscan" transaction={transaction}></StatusText>
        
     
        <HStack w="100%" p={2} justifyItems="end">
            <Box w={["100%", "50%", "50%" ]}>
                <ViewBallsButton onClick={getBallsMintedByWallet} waitingFor={waitingForBallsForGallery} w={['100%', null]} walletAddress={walletAddress}></ViewBallsButton>
            </Box>
            <Box w={["100%", "50%", "50%" ]}>
                <ConnectWalletButton float="right" onClick={connectWalletPressed} walletAddress={walletAddress}></ConnectWalletButton>
            </Box>
        </HStack>
        
        <BallGallery balls={ballsForGallery} isPressed={pressedForBalls}></BallGallery>
        
        <Heading as="h1" fontSize="2xl" mt={9} textAlign="center" lineHeight="100%" height="100%" verticalAlign="center" mb="1.75em">
            MUNICIPAL <em>Golf Club</em>
        </Heading>
        <Heading fontSize={['4em', '7em','11em', '17em']} textAlign="center" lineHeight={['0.8em']}>
            Golf<br/><em>on-chain</em>
        </Heading>
                
        <Box textAlign="center">
                <Container p="2em 0">
            <HStack margin="0 auto" justifyContent="center" >
                <VStack margin="0 auto" justifyContent="center" >
                <HStack margin="0 auto" justifyContent="center" >
                    <Button onClick={decrementCount} >-</Button>
                    <Box padding="0 5" width="2em">{amountToMint}</Box>
                    <Button onClick={incrementCount} >+</Button>
                    <MintButton onClick={onMintPressed} isMintingActive={mintActive} currentCount={amountToMint}></MintButton>
                </HStack>
                    <Text>{currentMintCount}/{totalSupply}</Text>
                </VStack>
            </HStack>
                    </Container>
        </Box>
        <ThwackText show={showThwack}></ThwackText>        
        </>
    )
}

export default Minter;