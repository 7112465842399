import { Flex, Link, Text } from "@chakra-ui/layout";

const StatusText = (props : any) => {
    const status : string = props.status;
    const displayText : string = props.displayText;
    // const transaction = props.transaction;
    if ( status === "" ) { return (<></>)};
    if ( status.startsWith("https") ) {return (
        <Flex w="100%" p={1} pr={3} bg="#003F30" color="white" flexDirection="row-reverse">
            <Link href={status} target="_blank">{displayText}</Link>
        </Flex>
    )};
    return (
        <>
        <Flex w="100%" p={1} pr={3} bg="#003F30" color="white" flexDirection="row-reverse">
            <Text>{status}</Text>
        </Flex>
        </>
    );
}

export default StatusText;