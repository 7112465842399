import { extendTheme } from "@chakra-ui/react";

const Td = {
    baseStyle: {
        backgroundColor: "red"
    },
    variants: {
        team : {
            padding: "0"
        }
    }
}

const MuniTheme = extendTheme({
    brand: {
        900: "#1a365d",
        800: "#153e75",
        700: "#2a69ac",
    },
    fonts: {
        heading: "Xanh Mono",
        body: "Xanh Mono",
    },
    styles: {
        global: {
            h2: {
                fontWeight: "400",
                lineHeight: "1.1"
            },
            body: {
                color: "004030"
            }
        }
    },
    components: {
        Container: {
            baseStyle: {
                maxWidth: "150ch",
            },
        },
        Heading: {
            baseStyle: {
                fontWeight: "400",
                lineHeight: "1.1"
            },
            sizes: {
                sm: {
                    lineHeight: "1.0"
                },
                md: {
                    lineHeight: "1.0"
                }
            }
        },
        Td,
        Button: {
            baseStyle: {
                borderRadius: "25px", 
                fontWeight: "400"
            }
        }
    }
})



export default MuniTheme;