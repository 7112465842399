import { Flex, Text, Image, Container} from "@chakra-ui/react";
import  Logos from '../assets/Logos.svg'; 

const Footer = (props : any) => {
    return(
        <>
        <Flex bg="white" marginTop="50px">
            <Container>
            <Text > 
                <Image margin="1em auto 3em" width="50%" src={Logos} />
            </Text>
                </Container>
        </Flex>
        </>
    );
}

export default Footer;