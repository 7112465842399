// const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY;

import Web3 from "web3";

const web3 = new Web3(Web3.givenProvider || "ws://localhost:8545");

const contractABI = require('./contract-abi.json');
const contractAddress = "0x06F650198CFCd186d45D9B2e564DB755DBc4A8cd";


export const getBalls = async( idNumbers : number[]) => {
    let balls = [];

    for await (const id of idNumbers) {
        let ball = getBall(id);
        try {
            balls.push((await ball).ballObject);
        } catch (error : any ) {

        }
    }

    return balls;
}

export const tryBalls = async( idNumbers : number[] ) => {
    let balls = getBalls( idNumbers );

    try {
        return balls;
    } catch {
        return 
    }
}


export const getMintedBallsByWallet = async( wallet : string ) => {
    let myContract = await new web3.eth.Contract(contractABI, contractAddress);

    try {
        const numberIssued = await myContract.methods.tokensOfOwner(wallet).call();

        return {
            success: true,
            status: "Received id numbers of balls minted by wallet.",
            ballIds: numberIssued
        }
    } catch ( error : any ) {
        return {
            success: false,
            status: error.message,
        }
    }
}

export const getNumberOfMintedBalls = async() => {
    let myContract = await new web3.eth.Contract(contractABI, contractAddress);

    try {
        const numberIssued = await myContract.methods.totalSupply().call();

        return {
            success: true,
            status: "Received number of balls minted so far.",
            issued: numberIssued,
            totalMax: "5400"
        }
    } catch ( error : any ) {
        return {
            success: false,
            status: error.message,
            issued: "?",
            totalMax: "?"
        }
    }
}

export const connectWallet = async() =>{
    if ( window.ethereum ) {
        try {
            const addressArray = window.ethereum.request({
                method: "eth_requestAccounts",
            });
            const obj = {
                status: "",
                address: addressArray[0],
            }
            return obj;
        } catch (err) {
            return {
                address : "",
                status : "Error: " + err,
            }
        }
    } else {
        return {
            address : "",
            status: "You must install MetaMask, a virtual Ethereum wallet, in your browser.",
        }
    }
};



export const getBall = async( t : number) => {

    let myContract = await new web3.eth.Contract(contractABI, contractAddress);

    try {
        const result = await myContract.methods.tokenURI(t).call();
        console.log(result);
        return {
            success: true,
            status: 'Ball: ' + processBall(result),
            ballObject: processBall(result)
        }
    } catch (error : any ) {
        return {
            success: false,
            status: 'Error: ' + error.message,
            ballObject: null
        }
    }
}

function processBall( ball : string ) : any {
    let text = "";

    text = ball.replace("data:application/json;base64,", "");
    text = atob(text);

    return JSON.parse(text);
}


export const checkMintActive = async() => {
    let myContract = await new web3.eth.Contract(contractABI, contractAddress);

    try {
        let isActive = await myContract.methods.mintingActive().call();

        console.log("Testing, " + isActive);
        return {
            success: isActive,
            status: "Minting is active."
        }
    } catch ( error : any ) {
        return {
            success: false,
            status: error.message
            
        }
    }
}


export const mintBall = async( amount : number ) => {

    await checkMintActive().then(
        (success) => {
            if ( !success ) {
                return {
                    success: false,
                    status: 'Minting is not currently active.'
                }
            }
        }
    );

    window.contract = await new web3.eth.Contract(contractABI, contractAddress);

    let price = await window.contract.methods.PRICE_PER_TOKEN().call();

    const gasPriceTransactionParameters = {
        to: contractAddress,
        from: window.ethereum.selectedAddress,
        value: web3.utils.toHex(price * amount),
        'data': window.contract.methods.mint( amount ).encodeABI()
    }
    
    const transactionParameters = {
        to: contractAddress,
        from: window.ethereum.selectedAddress,
        value: web3.utils.toHex(price * amount), 
        gas: "",
        'data': window.contract.methods.mint( amount ).encodeABI(),
        nonce: 0
    }

    web3.eth.estimateGas(gasPriceTransactionParameters).then( (gasAmount: any) =>{
            transactionParameters.gas = gasAmount;
    });

    //TODO Change for launch
    let etherURL : string = "https://etherscan.io/tx/"

    try {
        const txHash = await window.ethereum
            .request({
                method: 'eth_sendTransaction',
                params: [transactionParameters]
            });
        return {
            success: true,
            status: etherURL + txHash, 
            tx: txHash
        }
    } catch (error : any ) {
        return {
            success: false,
            status: 'Error: ' + error.message
        }
    }
}

export const getCurrentWalletConnected = async() => {
    if ( window.ethereum ) {
        try {
            const addressArray = await window.ethereum.request({
                method: "eth_accounts",
            });
            if ( addressArray.length > 0 ) {
                return {
                    address: addressArray[0],
                    status: "",
                };
            } else {
                return {
                    address: "",
                    status: "Connect to MetaMask",
                };
            }
        } catch (error : any) {
            return {
                address: "",
                status: error.message,
            };
        }
    } else {
        return {
            address: "",
            status: "You must install MetaMask, a virtual Ethereum wallet, in your browser.",
        };
    }
}