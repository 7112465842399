import "@fontsource/xanh-mono/400.css";
import "@fontsource/xanh-mono/400-italic.css";

import React from 'react';  
import './App.css';
import { ChakraProvider, Container, Heading, Link } from "@chakra-ui/react";
import { Image } from "@chakra-ui/image";
import MuniTheme from "./theme/MuniTheme";
import Footer from "./sections/Footer";
import MyGallery from "./sections/BallSlideShow";
import MunicipalCourse from './assets/Course-Map.png'; 
import Roadmap from "./sections/Roadmap";
import { DiscordLink } from "./components/DiscordLink";
// import ClosedMinter from "./sections/ClosedMinter";
import Minter from "./sections/Minter";
import Leaderboard from "./sections/Leaderboard";
// import BallPreviewer from "./sections/BallPreviewer";
// import ComingSoonHeader from "./sections/ComingSoonHeader";
// import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
// import { useState, useEffect } from "react";
// import MouseEffects from "./sections/MouseEffect";

function App({ Component } : any) {

    return (
        <ChakraProvider theme={MuniTheme}>
            <Minter></Minter>
            <MyGallery></MyGallery>
            <Leaderboard></Leaderboard>
            <Container>
                <Heading textAlign="center" fontSize={["4xl", "6xl", "8xl"]} mt="0.5em" mb="1em" lineHeight="1em">Now minting.<br/>Watch where the balls land in our <Link href={DiscordLink}><em>Discord</em></Link>.</Heading>
                <Image src={MunicipalCourse} margin="0px auto 4em" maxWidth="120ch" width="100%"/>
            </Container>
            
            <Roadmap></Roadmap>
            <Footer></Footer>
        </ChakraProvider>
    );
}

export default App;
    