import { Button } from "@chakra-ui/react";

const ConnectWalletButton = ( props : any ) => {

    const onClick = props.onClick;
    const walletAddress = props.walletAddress;

    if ( walletAddress !== undefined && walletAddress.length > 0 ) {
        return (
            <Button onClick={onClick} float="right" >
                {   
                "Connected: " + 
                String(walletAddress).substring(0, 6) + 
                "..." + 
                String(walletAddress).substring(38)
                }
            </Button>
        );
    }

    return (
        <Button onClick={onClick} float="right" bg="#003F30" color="white">
            {
                "Connect Wallet"
            }
        </Button>
    );
}

export default ConnectWalletButton;