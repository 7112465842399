//http://172.105.0.18:4000/api/leaderboard/yardage

import { Box, Divider, Image, Container, Heading, Grid, GridItem, Link } from "@chakra-ui/react";
import { Ball } from "./Ball";
import axios from 'axios';
import { useEffect, useState } from "react";


const BallInformation = ( props : any ) => {

    const ball : Ball = props.ball;
    const ballIndex = props.index;

    function getAttributes() : string {
        let ballAttributes = "";
        ball.attributes.forEach((attribute, index) => {
            if ( attribute.value !== "None" ) {
                
                ballAttributes += attribute.value;
                if ( attribute.trait_type === "Yardage") {
                    ballAttributes += " Yards"
                }
                ballAttributes += " / ";
            }
        });
        ballAttributes = ballAttributes.substring(0, ballAttributes.length - 3);

        return ballAttributes;
    }


    return(
        <>
        <Grid templateColumns='repeat(6, 1fr)' gap={5}>
            <GridItem colSpan={[3, 1]}>
                <Heading fontSize={["2em", "2em", "3em","3em"]}>
                    {ballIndex}
                </Heading>
            </GridItem>
            <GridItem colSpan={[3, 1]}>
                <Image key={ball.name} src={ball.image} width={"auto"}/>
            </GridItem>
            <GridItem colSpan={[3, 1]}>
                <Heading fontSize={["2em", "2em", "3em","3em"]}>
                    {ball.attributes[1].value + " Yards"}
                </Heading>
            </GridItem>
            <GridItem colSpan={[3, 1]}>
                <Heading fontSize={["2em", "2em", "3em","3em"]}>
                    {ball.name}
                </Heading>
            </GridItem>
            <GridItem colSpan={[6, 2]}>
                {getAttributes()}
                <br></br>
                <br></br>
                <Link href={"https://opensea.io/assets/0x06f650198cfcd186d45d9b2e564db755dbc4a8cd/" + ball.name.replace('Ball #', '')}><em>Opensea</em></Link>
            </GridItem>
        </Grid>
        <Box>
            <Divider borderColor="black" mb="10px" mt="10px"></Divider>
        </Box>
        </>
    );
}

const LeaderboardSlots = ( props : any ) => {
    const balls = props.balls;
    const optionListItems = balls.map((ball : Ball, index : number) => {
        return(<BallInformation key={ball.name} ball={ball} index={index + 1}/>);
    });
    return (
        <>
            {optionListItems}
        </>
    );
}

const Leaderboard = ( props : any ) => {

    const [leaderboardBalls, setLeaderboardBalls] = useState([]);

    useEffect(() => {
        async function fetchData() {
            axios.get("https://api.municipal.club/api/leaderboard/yardage")
            .then((response) => {
                const balls = JSON.parse(response.data);
                setLeaderboardBalls(Object.values(balls));
            });
        }
        fetchData();
    }, [])

    if ( leaderboardBalls.length > 0  ) {

        return (
            <>
                <Container>
                    <Heading fontSize="4xl">Current Leaderboard<br/><em>Yardage</em></Heading>
                    <Box>
                        <Divider borderColor="black" mb="10px" mt="10px"></Divider>
                    </Box>
                    <LeaderboardSlots balls={leaderboardBalls}></LeaderboardSlots>
                </Container>
            </>
        );
    }
    return (null);

}

export default Leaderboard;